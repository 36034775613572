@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: 'IBM Plex Sans', sans-serif;

	scrollbar-width: thin;
	scrollbar-color: #9ca7b8;
}

a {
	color: inherit;
	text-decoration: none;
}

*::-webkit-scrollbar {
	width: 0.5rem;
}

*::-webkit-scrollbar-thumb {
	background: #9ca7b8;
}
.smallscroll::-webkit-scrollbar {
	width: 0.3rem;
}

/* body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background: var(--tertiary);
}

body::-webkit-scrollbar-thumb {
  background: var(--primary);
}  */

@layer utilities {
	.page-padding {
		@apply px-[30px] py-5;
	}
}
#root {
	background-color: #f4f5f7;
}
